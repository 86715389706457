import { cloneDeep, omit } from '@/util/common';
import { ExternalRelayData, RelayItem, RelayItemBackEnd } from '../types/relay.d';

/**
 * relay初始值和格式生成
 * @param deviceType 设备类型，后端的标识 ,'0'|'1'|'2'|'3'|'50'
 * @param role  需要relay初始值和格式特殊变更的角色 'insOldCommunity' | 'insSingle' | 'pmNewCommunity' | 'pmOffice'| 'pmOldCommunity'
 * @param deviceCate 设备分类，1=社区公共设备,2=楼栋公共设备,3=家庭用户设备
 * @returns
 */
export function initRelayDataAndFormat(deviceType: string, role?: string, deviceCate?: string) {
    console.log('role', role);
    let relay: [] | RelayItem[] = [];
    const securityRelay: [] | RelayItem[] = [];
    let relayInitFormat: RelayItem = {
        name: { value: '', show: true, disabled: false },
        enable: { value: 1, show: true, disabled: false },
        dtmf: {
            value: '', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
        },
        unlock: {
            show: true,
            showHome: { value: 1, show: true },
            showTalking: { value: 1, show: true },
            pin: { value: 1, show: true },
            rf: { value: 1, show: true },
            face: { value: 1, show: true },
            ble: { value: 1, show: true },
            nfc: { value: 1, show: true }
        },
        schedule: { show: false, enable: 0, access: [] }
    };
    let securityRelayInitFormat: RelayItem = {
        name: { value: '', show: true, disabled: false },
        enable: { value: 1, show: true, disabled: false },
        dtmf: {
            value: '', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
        },
        unlock: {
            show: true,
            showHome: { value: 1, show: true },
            showTalking: { value: 1, show: true },
            pin: { value: 1, show: true },
            rf: { value: 1, show: true },
            face: { value: 1, show: true },
            ble: { value: 1, show: true },
            nfc: { value: 1, show: true }
        },
        schedule: { show: false, enable: 0, access: [] }
    };
    // Multi-tenants Doorphone type= 0;Single-tenant Doorphone：Type:1;Access Control：Type:50;
    if (deviceType === '0' || deviceType === '1') {
        relay = [{
            name: { value: `${WordList.ProperAllTextRelay}1`, show: true, disabled: false },
            enable: { value: 1, show: true, disabled: false },
            dtmf: {
                value: '#', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
            },
            unlock: {
                show: true,
                showHome: { value: 1, show: true },
                showTalking: { value: 1, show: true },
                pin: { value: 1, show: true },
                rf: { value: 1, show: true },
                face: { value: 1, show: true },
                ble: { value: 1, show: true },
                nfc: { value: 1, show: true }
            },
            schedule: { show: false, enable: 0, access: [] }
        }];
        // 在pm角色新社区、办公公共设备的Multi-tenants Doorphone、Single-tenant Doorphone、Access Control设备类型
        // 且是社区公共设备和单元公共设备下的relay才出现（securityRelay 没有schedule）
        if ((role === 'pmNewCommunity' || role === 'pmOffice') && (deviceCate === '1' || deviceCate === '2')) {
            relay = [{
                name: { value: `${WordList.ProperAllTextRelay}1`, show: true, disabled: false },
                enable: { value: 1, show: true, disabled: false },
                dtmf: {
                    value: '#', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
                },
                unlock: {
                    show: true,
                    showHome: { value: 1, show: true },
                    showTalking: { value: 1, show: true },
                    pin: { value: 1, show: true },
                    rf: { value: 1, show: true },
                    face: { value: 1, show: true },
                    ble: { value: 1, show: true },
                    nfc: { value: 1, show: true }
                },
                schedule: { show: true, enable: 0, access: [] }
            }];
            relayInitFormat = {
                name: { value: '', show: true, disabled: false },
                enable: { value: 1, show: true, disabled: false },
                dtmf: {
                    value: '', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
                },
                unlock: {
                    show: true,
                    showHome: { value: 1, show: true },
                    showTalking: { value: 1, show: true },
                    pin: { value: 1, show: true },
                    rf: { value: 1, show: true },
                    face: { value: 1, show: true },
                    ble: { value: 1, show: true },
                    nfc: { value: 1, show: true }
                },
                schedule: { show: true, enable: 0, access: [] }
            };
            securityRelayInitFormat = {
                name: { value: '', show: true, disabled: false },
                enable: { value: 1, show: true, disabled: false },
                dtmf: {
                    value: '', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
                },
                unlock: {
                    show: true,
                    showHome: { value: 1, show: true },
                    showTalking: { value: 1, show: true },
                    pin: { value: 1, show: true },
                    rf: { value: 1, show: true },
                    face: { value: 1, show: true },
                    ble: { value: 1, show: true },
                    nfc: { value: 1, show: true }
                },
                // pm securityRelay 也没有schedule
                schedule: { show: false, enable: 0, access: [] }
            };
        }

        if (role === 'pmOldCommunity') {
            relayInitFormat = {
                name: { value: '', show: true, disabled: false },
                enable: { value: 1, show: true, disabled: false },
                dtmf: {
                    value: '', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
                },
                unlock: {
                    show: true,
                    showHome: { value: 1, show: true },
                    showTalking: { value: 1, show: true },
                    pin: { value: 1, show: false },
                    rf: { value: 1, show: false },
                    face: { value: 1, show: false },
                    ble: { value: 1, show: false },
                    nfc: { value: 1, show: false }
                },
                schedule: { show: false, enable: 0, access: [] }
            };
            relay = [{
                name: { value: `${WordList.ProperAllTextRelay}1`, show: true, disabled: false },
                enable: { value: 1, show: true, disabled: false },
                dtmf: {
                    value: '#', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
                },
                unlock: {
                    show: true,
                    showHome: { value: 1, show: true },
                    showTalking: { value: 1, show: true },
                    pin: { value: 1, show: false },
                    rf: { value: 1, show: false },
                    face: { value: 1, show: false },
                    ble: { value: 1, show: false },
                    nfc: { value: 1, show: false }
                },
                schedule: { show: false, enable: 0, access: [] }
            }];
        }
    } else if (deviceType === '50') {
        relay = [{
            name: { value: `${WordList.ProperAllTextRelay}1`, show: true, disabled: false },
            enable: { value: 1, show: true, disabled: false },
            dtmf: {
                value: '#', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
            },
            unlock: {
                show: true,
                showHome: { value: 0, show: true },
                showTalking: { value: 1, show: false },
                pin: { value: 1, show: true },
                rf: { value: 1, show: true },
                face: { value: 1, show: true },
                ble: { value: 1, show: true },
                nfc: { value: 1, show: true }
            },
            schedule: { show: false, enable: 0, access: [] }
        }];
        relayInitFormat = {
            name: { value: '', show: true, disabled: false },
            enable: { value: 1, show: true, disabled: false },
            dtmf: {
                value: '', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
            },
            unlock: {
                show: true,
                showHome: { value: 0, show: true },
                showTalking: { value: 1, show: false },
                pin: { value: 1, show: true },
                rf: { value: 1, show: true },
                face: { value: 1, show: true },
                ble: { value: 1, show: true },
                nfc: { value: 1, show: true }
            },
            schedule: { show: false, enable: 0, access: [] }
        };
        securityRelayInitFormat = {
            name: { value: '', show: true, disabled: false },
            enable: { value: 1, show: true, disabled: false },
            dtmf: {
                value: '', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
            },
            unlock: {
                show: true,
                showHome: { value: 0, show: true },
                showTalking: { value: 1, show: false },
                pin: { value: 1, show: true },
                rf: { value: 1, show: true },
                face: { value: 1, show: true },
                ble: { value: 1, show: true },
                nfc: { value: 1, show: true }
            },
            schedule: { show: false, enable: 0, access: [] }
        };
        // 在pm角色新社区、办公公共设备的Multi-tenants Doorphone、Single-tenant Doorphone、Access Control设备类型
        // 且是社区公共设备和单元公共设备下的relay才出现（securityRelay 没有schedule）
        if ((role === 'pmNewCommunity' || role === 'pmOffice') && (deviceCate === '1' || deviceCate === '2')) {
            relay = [{
                name: { value: `${WordList.ProperAllTextRelay}1`, show: true, disabled: false },
                enable: { value: 1, show: true, disabled: false },
                dtmf: {
                    value: '#', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
                },
                unlock: {
                    show: true,
                    showHome: { value: 0, show: true },
                    showTalking: { value: 1, show: false },
                    pin: { value: 1, show: true },
                    rf: { value: 1, show: true },
                    face: { value: 1, show: true },
                    ble: { value: 1, show: true },
                    nfc: { value: 1, show: true }
                },
                schedule: { show: true, enable: 0, access: [] }
            }];
            relayInitFormat = {
                name: { value: '', show: true, disabled: false },
                enable: { value: 1, show: true, disabled: false },
                dtmf: {
                    value: '', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
                },
                unlock: {
                    show: true,
                    showHome: { value: 0, show: true },
                    showTalking: { value: 1, show: false },
                    pin: { value: 1, show: true },
                    rf: { value: 1, show: true },
                    face: { value: 1, show: true },
                    ble: { value: 1, show: true },
                    nfc: { value: 1, show: true }
                },
                schedule: { show: true, enable: 0, access: [] }
            };

            securityRelayInitFormat = {
                name: { value: '', show: true, disabled: false },
                enable: { value: 1, show: true, disabled: false },
                dtmf: {
                    value: '', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
                },
                unlock: {
                    show: true, showHome: { value: 0, show: true }, showTalking: { value: 1, show: false }, pin: { value: 1, show: true }, rf: { value: 1, show: true }, face: { value: 1, show: true }, ble: { value: 1, show: true }, nfc: { value: 1, show: true }
                },
                // pm securityRelay 也没有schedule
                schedule: { show: false, enable: 0, access: [] }
            };
        }

        if (role === 'insOldCommunity') {
            relayInitFormat = {
                name: { value: '', show: true, disabled: false },
                enable: { value: 1, show: true, disabled: false },
                dtmf: {
                    value: '', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
                },
                unlock: {
                    show: true, showHome: { value: 1, show: true }, showTalking: { value: 1, show: false }, pin: { value: 1, show: false }, rf: { value: 1, show: false }, face: { value: 1, show: false }, ble: { value: 1, show: false }, nfc: { value: 1, show: false }
                },
                schedule: { show: false, enable: 0, access: [] }
            };
            relay = [{
                name: { value: `${WordList.ProperAllTextRelay}1`, show: true, disabled: false },
                enable: { value: 1, show: true, disabled: false },
                dtmf: {
                    value: '#', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
                },
                unlock: {
                    show: true, showHome: { value: 1, show: true }, showTalking: { value: 1, show: false }, pin: { value: 1, show: false }, rf: { value: 1, show: false }, face: { value: 1, show: false }, ble: { value: 1, show: false }, nfc: { value: 1, show: false }
                },
                schedule: { show: false, enable: 0, access: [] }
            }];
        }

        if (role === 'pmOldCommunity') {
            relayInitFormat = {
                name: { value: '', show: true, disabled: false },
                enable: { value: 1, show: true, disabled: false },
                dtmf: {
                    value: '', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
                },
                unlock: {
                    show: true, showHome: { value: 1, show: true }, showTalking: { value: 1, show: false }, pin: { value: 1, show: false }, rf: { value: 1, show: false }, face: { value: 1, show: false }, ble: { value: 1, show: false }, nfc: { value: 1, show: false }
                },
                schedule: { show: false, enable: 0, access: [] }
            };
            relay = [{
                name: { value: `${WordList.ProperAllTextRelay}1`, show: true, disabled: false },
                enable: { value: 1, show: true, disabled: false },
                dtmf: {
                    value: '#', show: true, disabled: false, showSmartPlusTalkingLimitTip: false
                },
                unlock: {
                    show: true, showHome: { value: 1, show: true }, showTalking: { value: 1, show: false }, pin: { value: 1, show: false }, rf: { value: 1, show: false }, face: { value: 1, show: false }, ble: { value: 1, show: false }, nfc: { value: 1, show: false }
                },
                schedule: { show: false, enable: 0, access: [] }
            }];
        }
    } else if (deviceType === '2') {
        // Indoor Monitor：Type:2
        relay = [{
            name: { value: `${WordList.ProperAllTextRelay}1`, show: true, disabled: false },
            enable: { value: 0, show: true, disabled: false },
            dtmf: {
                value: '#', show: false, disabled: false, showSmartPlusTalkingLimitTip: false
            },
            unlock: {
                show: false,
                showHome: { value: 1, show: true },
                showTalking: { value: 1, show: true },
                pin: { value: 1, show: true },
                rf: { value: 1, show: true },
                face: { value: 1, show: true },
                ble: { value: 1, show: true },
                nfc: { value: 1, show: true }
            },
            schedule: { show: false, enable: 0, access: [] }
        },
        {
            name: { value: `${WordList.ProperAllTextRelay}2`, show: true, disabled: false },
            enable: { value: 0, show: true, disabled: false },
            dtmf: {
                value: '0', show: false, disabled: false, showSmartPlusTalkingLimitTip: false
            },
            unlock: {
                show: false,
                showHome: { value: 1, show: true },
                showTalking: { value: 1, show: true },
                pin: { value: 1, show: true },
                rf: { value: 1, show: true },
                face: { value: 1, show: true },
                ble: { value: 1, show: true },
                nfc: { value: 1, show: true }
            },
            schedule: { show: false, enable: 0, access: [] }
        }];
    } else if (deviceType === '3') {
        // Guard Phone（单住户无此设备）：无relay,Type:3
    }
    console.log({
        relay,
        securityRelay,
        relayInitFormat,
        securityRelayInitFormat
    });
    return {
        relay,
        securityRelay,
        relayInitFormat,
        securityRelayInitFormat
    };
}
/**
 * 前后端realy格式互转
 * @param data 前端数组格式或者后端json字符串
 * @param type 表示前端转后端和后端转前端
 * @param role 角色，根据不同角色处理格式,'insOldCommunity' | 'insSingle' |'pmOldCommunity'| 'pmNewCommunity' | 'pmOffice'|''
 * @param deviceType 设备类型，后端的设备标识，根据不同的设备类型处理格式
 * @param deviceCate 设备分类，1=社区公共设备,2=单元公共设备,3=家庭用户设备
 * @returns
 */
interface TransformRelayFormatParams {
    data: RelayItem[] | string;
    type: 'frontEndToBackEnd' | 'backEndToFrontEnd';
    role?: string;
    deviceType?: string;
    deviceCate?: string;
    relayType?: string;
}
// eslint-disable-next-line consistent-return
export function transformRelayFormat({
    data, type, role, deviceType, deviceCate, relayType
}: TransformRelayFormatParams = { data: [], type: 'frontEndToBackEnd' }) {
    if (type === 'frontEndToBackEnd' && data instanceof Array) {
        if (data.length === 0) {
            return '';
        }
        const result: RelayItemBackEnd[] = [];
        const d = data as RelayItem[];
        d.forEach((item) => {
            result.push({
                name: item.name.value,
                dtmf: item.dtmf.value,
                enable: item.enable.value,
                showHome: item.unlock.showHome.value,
                showTalking: item.unlock.showTalking.value,
                accessControl: {
                    pin: item.unlock.pin.value,
                    rf: item.unlock.rf.value,
                    face: item.unlock.face.value,
                    ble: item.unlock.ble.value,
                    nfc: item.unlock.nfc.value
                },
                schedule: {
                    enable: item.schedule.enable,
                    access: item.schedule.access
                }
            });
        });
        return JSON.stringify(result);
    }

    if (type === 'backEndToFrontEnd' && typeof data === 'string') {
        if (data === '') {
            // 历史原因：室内机一开始没有relay,会存在后端值为空字符串的情况
            if (relayType === 'relay' && deviceType === '2') {
                return [{
                    name: { value: `${WordList.ProperAllTextRelay}1`, show: true, disabled: false },
                    enable: { value: 0, show: true, disabled: false },
                    dtmf: {
                        value: '#', show: false, disabled: false, showSmartPlusTalkingLimitTip: false
                    },
                    unlock: {
                        show: false,
                        showHome: { value: 1, show: true },
                        showTalking: { value: 1, show: true },
                        pin: { value: 1, show: true },
                        rf: { value: 1, show: true },
                        face: { value: 1, show: true },
                        ble: { value: 1, show: true },
                        nfc: { value: 1, show: true }
                    },
                    schedule: { show: false, enable: 0, access: [] }
                },
                {
                    name: { value: `${WordList.ProperAllTextRelay}2`, show: true, disabled: false },
                    enable: { value: 0, show: true, disabled: false },
                    dtmf: {
                        value: '0', show: false, disabled: false, showSmartPlusTalkingLimitTip: false
                    },
                    unlock: {
                        show: false,
                        showHome: { value: 1, show: true },
                        showTalking: { value: 1, show: true },
                        pin: { value: 1, show: true },
                        rf: { value: 1, show: true },
                        face: { value: 1, show: true },
                        ble: { value: 1, show: true },
                        nfc: { value: 1, show: true }
                    },
                    schedule: { show: false, enable: 0, access: [] }
                }];
            }
            return [];
        }
        const dataTemp = JSON.parse(data as string) as RelayItemBackEnd[];
        const isShow = (field: string) => {
            if (field === 'unlock') {
                // deviceType 为2也不展示unlock
                if (deviceType === '2') {
                    return false;
                }
                return true;
            }
            if (field === 'schedule') {
                if ((role === 'pmNewCommunity' || role === 'pmOffice') && (deviceCate === '1' || deviceCate === '2')) {
                    return true;
                }
                return false;
            }
            if (field === 'dtmf') {
                if (deviceType === '2') {
                    return false;
                }
                return true;
            }
            return true;
        };
        const isShowTalking = () => {
            if (deviceType === '50') {
                return false;
            }
            return true;
        };
        const result: RelayItem[] = [];
        dataTemp.forEach((item) => {
            result.push(
                {
                    name: {
                        value: item.name,
                        show: true,
                        disabled: false
                    },
                    enable: {
                        value: item.enable,
                        show: true,
                        disabled: false
                    },
                    dtmf: {
                        value: item.dtmf,
                        show: isShow('dtmf'),
                        disabled: false,
                        showSmartPlusTalkingLimitTip: false
                    },
                    unlock: {
                        show: isShow('unlock'),
                        showHome: { show: true, value: item.showHome },
                        showTalking: { show: isShowTalking(), value: item.showTalking },
                        pin: { show: role !== 'pmOldCommunity', value: item.accessControl.pin },
                        rf: { show: role !== 'pmOldCommunity', value: item.accessControl.rf },
                        face: { show: role !== 'pmOldCommunity', value: item.accessControl.face },
                        ble: { show: role !== 'pmOldCommunity', value: item.accessControl.ble },
                        nfc: { show: role !== 'pmOldCommunity', value: item.accessControl.nfc }
                    },
                    schedule: {
                        show: isShow('schedule'),
                        enable: item.schedule.enable,
                        access: item.schedule.access
                    }
                }
            );
        });
        showTalkingLimitTip(result);
        return result;
    }
}

/**
 * 未勾选SmartPlus Talking Page的relay和勾选的relay不能设置一样的DTMF Code(排除关闭状态的)，若一样，则显示提示语
 * @param source relay前端格式
 */
export function showTalkingLimitTip(source: RelayItem[]) {
    const data = source;
    const showTalkingEnableList = data.filter((item) => item.unlock.showTalking.value === 1);
    const showTalkingCloseList = data.filter((item) => item.unlock.showTalking.value === 0);
    let hasEqualVal = false;
    for (let i = 0; i < showTalkingEnableList.length; i++) {
        for (let j = 0; j < showTalkingCloseList.length; j++) {
            if (showTalkingEnableList[i].enable.value === 1 && showTalkingCloseList[j].enable.value === 1
              && showTalkingEnableList[i].dtmf.value === showTalkingCloseList[j].dtmf.value) {
                hasEqualVal = true;
                break;
            }
        }
        if (hasEqualVal) {
            break;
        }
    }
    if (hasEqualVal) {
        data.forEach((item, index) => {
            data[index].dtmf.showSmartPlusTalkingLimitTip = true;
        });
    } else {
        data.forEach((item, index) => {
            data[index].dtmf.showSmartPlusTalkingLimitTip = false;
        });
    }
}

export const externalRelayInitFormat: ExternalRelayData['RelayList'][number] = {
    Name: '',
    RelaySwitch: true,
    RelayFunction: 'Light'
};

interface TransformExternalRelayFormatParam {
    data: string | ExternalRelayData;
    type: 'frontEndToBackEnd' | 'backEndToFrontEnd';
    deviceType: string;
}

type TransformExternalRelayFormatReturn<T extends TransformExternalRelayFormatParam> = T['type'] extends 'frontEndToBackEnd'
    ? string
    : ExternalRelayData;

export function transformExternalRelayFormat<T extends TransformExternalRelayFormatParam>({
    data,
    type,
    deviceType
}: T): TransformExternalRelayFormatReturn<T> {
    if (type === 'backEndToFrontEnd') {
        const backendExternalRelayData = JSON.parse(data as string);

        if (backendExternalRelayData.RelayList.length === 0) {
            const defaultRelay = cloneDeep(externalRelayInitFormat);
            defaultRelay.Name = 'External Relay1';
            backendExternalRelayData.RelayList.push(defaultRelay);
        }

        return {
            ...backendExternalRelayData,
            ShowExternalRelay: deviceType === '2'
        } as TransformExternalRelayFormatReturn<T>;
    }
    const frontendExternalRelayData = data as ExternalRelayData;

    // 不展示额外relay时，即非室内机，不传递参数
    if (!frontendExternalRelayData.ShowExternalRelay) {
        return '' as TransformExternalRelayFormatReturn<T>;
    }

    return JSON.stringify(omit(frontendExternalRelayData, ['ShowExternalRelay'])) as TransformExternalRelayFormatReturn<T>;
}
