/**
 * 返回一个新对象，保留原始对象内除指定key外的值
 * @date 2024-04-23
 * @param {any} obj: 原始对象
 * @param {any} keys: 要去除的key
 * @returns {any}
 */
export function omit<
    T extends object,
    U extends Array<keyof T>
>(obj: T, keys: U): Pick<T, Exclude<keyof T, U[number]>> {
    const resObj = {} as Pick<T, Exclude<keyof T, U[number]>>;
    (Object.keys(obj) as Array<keyof T>).forEach((key) => {
        if (!keys.includes(key)) {
            (resObj as T)[key] = obj[key];
        }
    });
    return resObj;
}

export function cloneDeep<T>(value: T): T {
    // 处理原始类型（null、undefined、number、string、boolean、symbol、bigint）
    if (value === null || typeof value !== 'object') {
        return value;
    }

    // 处理数组
    if (Array.isArray(value)) {
        const newArr = value.map((item) => cloneDeep(item));
        return newArr as unknown as T;
    }

    // 处理对象
    const newObj: Record<string, any> = {};
    Object.keys(value).forEach((key) => {
        newObj[key] = cloneDeep((value as Record<string, any>)[key]);
    });
    return newObj as T;
}
